import mainDataProviderClient, {
    RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";
import NumberFormatter from "../utils/numberFormatterUtils";
import { Description } from "@mui/icons-material";

const orderDataProvider = {
    create: async (resource, params) => {
        let formData = new FormData();
        formData.append("name", params.data["name"]);
        formData.append("nameAr", params.data["nameAr"]);
        formData.append("file", params.data["image"].rawFile);

        try {
            const response = await mainDataProviderClient(
                `${resource}`,
                RequestTypes.create,
                {
                    method: "POST",
                    body: formData,
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? `Failed to create ${resource}`);
        }
    },
    getList: async (resource, params) => {
        const url = createUrl(`order/invoice-report`, params);

        const response = await mainDataProviderClient(url, RequestTypes.getAll);
        return {
            data: response.data.map((item) => ({
                id: item.id,
                invoiceNumber: item.invoiceNumber,
                total: NumberFormatter.instance.formatNumber({ style: 'currency', currency: 'AED', maximumFractionDigits: 2, trailingZeroDisplay: "stripIfInteger" }, item.totalAmount),
                customerName: item.customerName,
                dueDate: item.dueDate,
                dueTime: item.dueTime,
                status: item.status
            })),
            total: response?.total,
        };
    },

    getOne: async (resource, params) => {

        const response = await mainDataProviderClient(
            `order/${params.id}`,
            RequestTypes.getById
        );


        return {
            data: {
                ...response.data,
                id: params.id,
                invoiceNumber: response.data.keyOrder,
                totalAmount: NumberFormatter.instance.formatNumber({ style: 'currency', currency: 'AED', maximumFractionDigits: 2, trailingZeroDisplay: "stripIfInteger" }, response.data.totalAmount),
                customerName: response.data.customerName,
                scheduledDate: response.data.scheduledDate,
                scheduledTime: response.data.scheduledTime,
                status: response.data.status,
                deliveryInstruction: response.data.deliveryInstruction ?? "",
                products: response.data.products?.map((prod) => {
                    return {
                        id: prod.id,
                        barcode: prod.sku,
                        image: prod.imageUrls.length > 0 ? prod.imageUrls[0].imageUrl : null,
                        name: prod.name,
                        description: prod.description,
                        quantity: NumberFormatter.instance.formatNumber({ notation: "compact", maximumFractionDigits: 2 }, prod.quantity),
                        price: NumberFormatter.instance.formatNumber(
                            {
                                style: "currency",
                                currency: "AED",
                                maximumFractionDigits: 2,
                                trailingZeroDisplay: "stripIfInteger",
                            },
                            prod.price
                        ),
                        unit: prod.priceModel.unit ?? "",
                        country: prod.country.country,
                        isActive: prod.isActive

                    }
                }) ?? []
            },
        };
    },

    update: async (resource, params) => {
        let formData = new FormData();
        formData.append("name", params.data["name"]);
        formData.append("nameAr", params.data["nameAr"]);
        formData.append("file", params.data["image"].rawFile);

        try {
            const response = await mainDataProviderClient(
                `${resource}/${params.id}`,
                RequestTypes.update,
                {
                    method: "PATCH",
                    body: formData,
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? "Failed to update Tag");
        }
    },

    updateOrderQuantity: async (resource, params) => {
        try {
            let data = {
                orderId: params.orderId,
                productId: params.productId,
                newQuantity: parseInt(params.newQuantity)
            }

            const response = await mainDataProviderClient(
                `${resource}/`,
                RequestTypes.update,
                {
                    method: "PUT",
                    body: JSON.stringify(data),
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? "Failed to update Order Quantity");
        }
    },
    updateOrderStatus: async (resource, params) => {
        try {
            let data = {
                orderId: params.orderId['*'],
                newStatus: params.newStatus
            }
            const response = await mainDataProviderClient(
                `${resource}/update-status`,
                RequestTypes.update,
                {
                    method: "PUT",
                    body: JSON.stringify(data),
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? "Failed to update Order Status");
        }
    },

};
export default orderDataProvider;
