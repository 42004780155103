import { Show, TabbedShowLayout, useNotify, useRefresh } from "react-admin";
import { CommonView } from "./CommonView";
import { getFieldType } from "../../utils/fieldUtils";
import { useEffect, useState } from "react";
import orderDataProvider from "../../dataProviders/orderDataProvider";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const OrderView = () => {
  const { id } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();

  const [csvData, setCsvData] = useState([]);
  const [orderData, setOrderData] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const orderDataResponse = await orderDataProvider.getOne("order", {
          id,
        });
        console.log("Data provider: ", orderDataResponse);

        const orderInfo = orderDataResponse.data;

        setCsvData([
          {
            Invoice_Number: orderInfo.invoiceNumber,
            Total_Amount: orderInfo.totalAmount,
            Scheduled_Date: orderInfo.scheduledDate,
            Scheduled_Time: orderInfo.scheduledTime,
            Customer_Name: orderInfo.customerName,
            Products: orderInfo.products
              .map((prod) => `${prod.name} (${prod.quantity})`)
              .join(", "),
            Address: orderInfo.address.address,
          },
        ]);

        setOrderData(orderInfo);
        setScheduledDate(new Date(orderInfo.scheduledDate)); // Initialize scheduled date
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderData();
  }, [id]);

  const handleRescheduleOrder = async () => {
    try {
      const updatedOrderData = { ...orderData, scheduledDate };

      await orderDataProvider.update("order", {
        id: orderData.id,
        data: updatedOrderData,
      });
      notify("Order rescheduled successfully", "info");
      refresh();
    } catch (error) {
      console.error("Error rescheduling order:", error);
      notify("Failed to reschedule order", "error");
    }
  };

  const generatePDF = () => {
    if (!orderData) return;

    const doc = new jsPDF();
    // Add title
    doc.setFontSize(20);
    doc.text(
      `Invoice ${orderData.invoiceNumber} Details:`,
      105,
      10,
      null,
      null,
      "center"
    );

    // Reset font size for content
    doc.setFontSize(12);
    let y = 30;
    // Order Details Table
    doc.autoTable({
      startY: 14,
      head: [
        [
          "Invoice Number",
          "Total Amount",
          "Scheduled Date",
          "Scheduled Time",
          "Customer Name",
        ],
      ],
      body: [
        [
          orderData.invoiceNumber,
          orderData.totalAmount,
          orderData.scheduledDate,
          orderData.scheduledTime,
          orderData.customerName,
        ],
      ],
    });

    // Products Table
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: [["Barcode", "Name", "Description", "Quantity", "Price", "Unit"]],
      body: orderData.products.map((prod) => [
        prod.barcode,
        prod.name,
        prod.description,
        prod.quantity,
        prod.price,
        prod.unit,
      ]),
    });

    // Address Table
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: [
        [
          "Address Title",
          "Address",
          "Street",
          "Nearest Land",
          "Contact Number",
        ],
      ],
      body: [
        [
          orderData.address.addressTitle,
          orderData.address.address,
          orderData.address.street,
          orderData.address.nearestLand,
          orderData.address.contactNumber,
        ],
      ],
    });

    doc.save("order.pdf");
  };

  const onClickReschedule = () => {};

  return (
    <Show>
      <TabbedShowLayout>
        {/* General Tab */}
        <TabbedShowLayout.Tab label="General">
          <CommonView
            fields={[
              "invoiceNumber",
              "customerName",
              "totalAmount",
              "scheduledDate",
              "scheduledTime",
              "status",
              "deliveryInstruction",
            ]}
            isView={true}
            labels={[
              "Invoice Number",
              "Customer Name",
              "Total Amount",
              "Scheduled Date",
              "Scheduled Time",
              "Status",
              "Delivery Instruction",
            ]}
          />
          {/* <div style={{ marginTop: "10px" }}>
            <button
              onClick={() => handleRescheduleOrder()}
              style={{
                backgroundColor: "#1976D2",
                border: "none",
                padding: "10px",
                marginRight: "20px",
                cursor: "pointer",
                color: "#ffffff",
              }}
            >
              Reschedule Order
            </button>
            {scheduledDate && (
              <div style={{ marginTop: "10px" }}>
                <DatePicker
                  selected={scheduledDate}
                  onChange={(date) => setScheduledDate(date)}
                  // dateFormat="dd/MM/yyyy"
                  placeholderText="Select a date"
                />
              </div>
            )}
          </div> */}
        </TabbedShowLayout.Tab>

        {/* Address and Products Tabs */}
        {/* Same as before */}

        <div style={{ marginLeft: "auto", marginTop: "10px" }}>
          <button
            onClick={onClickReschedule}
            style={{
              backgroundColor: "#1976D2",
              border: "none",
              padding: "10px",
              marginRight: "20px",
              marginBottom: "10px",
              cursor: "pointer",
              color: "#ffffff",
            }}
          >
            Reschedule Order
          </button>
          <button
            style={{
              backgroundColor: "#1976D2",
              border: "none",
              padding: "10px",
              marginRight: "20px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          >
            <CSVLink
              data={csvData}
              filename="order.csv"
              style={{ textDecoration: "none", color: "#ffffff" }}
            >
              Export Order as CSV
            </CSVLink>
          </button>
          <button
            onClick={generatePDF}
            style={{
              backgroundColor: "#1976D2",
              border: "none",
              padding: "10px",
              marginRight: "20px",
              marginBottom: "10px",
              cursor: "pointer",
              color: "#ffffff",
            }}
          >
            Export Order as PDF
          </button>
        </div>
      </TabbedShowLayout>
    </Show>
  );
};

export default OrderView;
