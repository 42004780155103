import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import NumberFormatter from "../utils/numberFormatterUtils";
import DateFormatter from "../utils/dateFormatterUtils";
import { createUrl } from "../utils/urlUtils";

const productDataProvider = {
  deleteMany: async (resource, params) => {
    let body = {
      productIds: params.ids,
    };

    try {
      await mainDataProviderClient(
        `${resource}/delete-products`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: JSON.stringify(body),
        }
      );

      return { data: [] };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update product");
    }
  },
  getList: async (resource, params) => {
    const url = createUrl(resource, params);

    const response = await mainDataProviderClient(url, RequestTypes.getAll);

    return {
      data: response.data.map((product) => ({
        ...product,
        brand: product.brand.source,
        createdAt: DateFormatter.instance.formatDateUTC(product.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(product.updatedAt),
        image:
          product.imageUrls.length > 0 ? product.imageUrls[0].imageUrl : null,
        country: product.country.country,
        subCategories: product.subCategories.name,
        unit: product.priceModel?.unit ?? "",

        quantity: NumberFormatter.instance.formatNumber(
          {
            notation: "compact",
            maximumFractionDigits: 2,
          },
          product.stockQuantity
        ),
        formatted_price: NumberFormatter.instance.formatNumber(
          {
            style: "currency",
            currency: "AED",
            maximumFractionDigits: 2,
            trailingZeroDisplay: "stripIfInteger",
          },
          product.price
        ),
      })),
      total: response.total,
    };
  },

  update: async (resource, params) => {
    let formData = new FormData();
    formData.append("name", params.data["name"]);
    formData.append("sort_order", params.data["SortOrder"]);
    formData.append("barcode", params.data["barcode"] || 0);
    formData.append("brandId", params.data["brandId"]);
    formData.append("priceModel", params.data["unitId"]);
    formData.append("sku", params.data["Sku"]);
    formData.append("country", params.data["countryId"]);
    formData.append("price", `${params.data["price"]}`);
    formData.append("description", params.data["description"]);
    formData.append("subCategoryId", params.data["subcategoryId"]);
    formData.append("storage", params.data["storage"]);
    formData.append("ingredient", params.data["ingredient"]);
    formData.append("discountValue", params.data["discountValue"]);
    if (params.data["images"]) {
      for (let i = 0; i < params.data["images"].length; i++) {
        formData.append(`files`, params.data["images"][i].rawFile);
      }
    }

    for (let i = 0; i < params.data["tagIds"].length; i++) {
      formData.append(`tags[${i}]`, params.data["tagIds"][i]);
    }

    if (params.data["nutrition"].description) {
      formData.append(
        `nutrition[0][description]`,
        params.data["nutrition"].description
      );
      formData.append(
        `nutrition[0][measure]`,
        params.data["nutrition"].measure
      );
      formData.append(`nutrition[0][nrv]`, params.data["nutrition"].nrv);
      formData.append(
        `nutrition[0][per_100g_ml]`,
        params.data["nutrition"].per_100g_ml
      );
      formData.append(
        `nutrition[0][per_serving]`,
        params.data["nutrition"].per_serving
      );
    } else {
      formData.append(`nutrition[]`, []);
    }
    formData.append("nameAr", params.data["nameAr"]);
    formData.append("descriptionAr", params.data["descriptionAr"]);
    formData.append("generalDescription", params.data["generalDescription"]);
    formData.append(
      "generalDescriptionAr",
      params.data["generalDescriptionAr"]
    );
    formData.append("storageAr", params.data["storageAr"]);
    formData.append("ingredientAr", params.data["ingredientAr"]);
    formData.append("quantity", params.data["quantity"]);
    formData.append("isActive", params.data["isActive"]);
    formData.append("isFeatured", params.data["isFeatured"]);

    try {
      const response = await mainDataProviderClient(
        `${resource}/update-product/${params.id}`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: formData,
        }
      );

      return { data: { ...response.data, id: params.id } };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update product");
    }
  },

  create: async (resource, params) => {
    let formData = new FormData();
    formData.append("name", params.data["name"]);
    formData.append("sku", params.data["sku"]);
    formData.append("sort_order", params.data["sort_order"]);
    formData.append("barcode", params.data["barcode"]);
    formData.append("brandId", params.data["brandId"]);
    formData.append("priceModel", params.data["unitId"]);

    formData.append("country", params.data["countryId"]);
    formData.append("price", `${params.data["price"]}`);
    formData.append("description", params.data["description"]);
    formData.append("subCategoryId", params.data["subcategoryId"]);
    formData.append("storage", params.data["storage"]);
    formData.append("ingredient", params.data["ingredient"]);
    formData.append("discountValue", params.data["discountValue"]);
    if (params.data["images"]) {
      for (let i = 0; i < params.data["images"].length; i++) {
        formData.append(`files`, params.data["images"][i].rawFile);
      }
    }
    for (let i = 0; i < params.data["tags"].length; i++) {
      formData.append(`tags[${i}]`, params.data["tags"][i]);
    }

    if (params.data["nutrition"].description) {
      formData.append(
        `nutrition[0][description]`,
        params.data["nutrition"].description
      );
      formData.append(
        `nutrition[0][measure]`,
        params.data["nutrition"].measure
      );
      formData.append(`nutrition[0][nrv]`, params.data["nutrition"].nrv);
      formData.append(
        `nutrition[0][per_100g_ml]`,
        params.data["nutrition"].per_100g_ml
      );
      formData.append(
        `nutrition[0][per_serving]`,
        params.data["nutrition"].per_serving
      );
    } else {
      formData.append(`nutrition[]`, []);
    }
    formData.append("nameAr", params.data["nameAr"]);
    formData.append("descriptionAr", params.data["descriptionAr"]);
    formData.append("generalDescription", params.data["generalDescription"]);
    formData.append(
      "generalDescriptionAr",
      params.data["generalDescriptionAr"]
    );
    formData.append("storageAr", params.data["storageAr"]);
    formData.append("ingredientAr", params.data["ingredientAr"]);
    formData.append("quantity", params.data["quantity"]);
    formData.append("isActive", params.data["isActive"]);
    formData.append("isFeatured", params.data["isFeatured"]);

    try {
      const response = await mainDataProviderClient(
        `${resource}`,
        RequestTypes.create,
        {
          method: "POST",
          body: formData,
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to create product");
    }
  },

  getOne: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.getById
    );

    return {
      data: {
        ...response.data,
        id: params.id,
        barcode: response.data?.barcode ?? response.data?.sku,
        createdAt: DateFormatter.instance.formatDateUTC(response.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(response.updatedAt),
        name: response.data.name,
        sku: response.data?.sku,
        sort_order: response.data?.sort_order,
        nameAr: response.data.nameAr,
        images: response.data.imageUrls.map((item) => {
          return { src: item.imageUrl };
        }),
        price: response.data.price,
        subcategoryId: response.data.subCategories.id,
        subcategory: response.data.subCategories.name,
        brandId: response.data.brand.id,
        brand: response.data.brand.source,
        countryId: response.data.country.id,
        country: response.data.country.country,
        unitId: response.data.priceModel.id,
        unit: response.data.priceModel.unit,
        description: response.data.description,
        descriptionAr: response.data.descriptionAr,
        discountValue:
          response.data.discountValue != "null"
            ? response.data.discountValue
            : 0,
        quantity: response.data.stockQuantity,
        isDiscounted: response.data.isDiscount,
        tagIds: response.data.tags.map((item) => {
          return item.id;
        }),
        tags: response.data.tags,
        generalDescription: response.data.generalDescription,
        generalDescriptionAr: response.data.generalDescriptionAr,
        ingredient: response.data.ingredient,
        ingredientAr: response.data.ingredientAr,
        storage: response.data.storage,
        storageAr: response.data.storageAr,
        nutrition: {
          description:
            response.data.nutrition?.length > 0
              ? response.data.nutrition[0].description
              : "",
          per_100g_ml:
            response.data.nutrition?.length > 0
              ? response.data.nutrition[0].per_100g_ml
              : "",
          per_serving:
            response.data.nutrition?.length > 0
              ? response.data.nutrition[0].per_serving
              : "",
          measure:
            response.data.nutrition?.length > 0
              ? response.data.nutrition[0].measure
              : "",
          nrv:
            response.data.nutrition?.length > 0
              ? response.data.nutrition[0].nrv
              : "",
        },
        isActive: response.data.isActive,
        isFeatured: response.data.isFeatured,
      },
    };
  },

  createMany: async (resource, data) => {
    const items = data;
    const body = { products: [] };

    items.forEach((element) => {
      if (element.sheetName === "Product") {
        body.products = element.data.map((product) => ({
          barcode: product?.barcode,
          brand: product?.brand,
          country: product?.country,
          description: product?.description,
          descriptionAr: product?.descriptionAr,
          discountValue: product?.discountValue,
          generalDescription: product?.generalDescription,
          generalDescriptionAr: product?.generalDescriptionAr,
          isActive: product?.isActive,
          name: product?.name,
          nameAr: product?.nameAr,
          price: product?.price,
          sku: product?.sku,
          source: product?.source,
          quantity: product?.quantity,
          subcategory: product?.subcategory,
          unit: product?.unit,
          isFeatured: product?.isFeatured,
          storage: product?.storage,
          storageAr: product?.storageAr,
          ingredient: product?.ingredient,
          ingredientAr: product?.ingredientAr,
        }));
      }
    });

    console.log(body);

    try {
      const response = await mainDataProviderClient(
        `${resource}/create-bulk-product`,
        RequestTypes.create,
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );

      return { data: response.data };
    } catch (error) {
      if (Array.isArray(error.message) && error.message.length > 0) {
        throw new Error(error.message[0].message ?? "Failed to create product");
      } else {
        throw new Error(error.message ?? "Failed to create product");
      }
    }
  },

  updateMany: async (resource, data) => {
    try {
      let products = data[0].data;
      const body = { products: [] };

      body.products = products.map((product) => {
        return {
          id: product["id"],
          price: product["price"],
          name: product["name"],
          nameAr: product["nameAr"],
          price: product["price"],
          sku: product["Sku"],
          barcode: product["barcode"],
          description: product["description"],
          descriptionAr: product["descriptionAr"],
          discountValue: product["discountValue"],
          generalDescription: product["generalDescription"],
          generalDescriptionAr: product["generalDescriptionAr"],
          ingredient: product["ingredient"],
          ingredientAr: product["ingredientAr"],
          isActive: product["isActive"],
          stockQuantity: product["stockQuantity"],
          storage: product["storage"],
          storageAr: product["storageAr"],
        };
      });

      const response = await mainDataProviderClient(
        `${resource}/update-bulk-product`,
        RequestTypes.update,
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update products in bulk");
    }
  },

  delete: async (resource, params) => {
    try {
      await mainDataProviderClient(
        `${resource}/force-delete/${params.id}`,
        RequestTypes.delete,
        {
          method: "DELETE",
        }
      );
      return { data: null };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update product");
    }
  },
};

export default productDataProvider;
